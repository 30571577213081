/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Donation
 * Description: Donation
 * Date: 15/07/2022
 */

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useDonation } from './ContextAPI';
import DrugTable from './DrugTable';
import { reload } from '../../../Utils';
import InstantOuterAgency from './InstantOuterAgency';

export default function FormInfo() {
    const { setShow, isSubmitted, setIsSubmitted, rowDataList } = useDonation();
    const { agencies, getAgencies } = CommonApiService();

    const axiosPrivate = useAxiosPrivate();
    const [agencyData, setAgencyData] = useState();
    const [drugList, setDrugList] = useState();
    const [loading, setloading] = useState(false);

    const {
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        control,
        clearErrors,
        reset,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const dataList = useCallback(() => {
        getAgencies();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setAgencyData(
            agencies?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [agencies]);

    const durationData = [
        { label: 'all', value: 0 },
        { label: '3', value: 3 },
        { label: '6', value: 6 },
        { label: '9', value: 9 },
        { label: '12', value: 12 },
    ];

    useEffect(() => {
        if (!isEmpty(rowDataList)) {
            setValue('drug_list', rowDataList);
        }
    }, [rowDataList]);

    register('drug_list', { required: 'Donation quantity is required!' });

    useEffect(() => {
        clearErrors('drug_list');
    }, [rowDataList]);

    // Form Close
    const handleClose = () => {
        setShow(false);
    };

    const getDrugList = async (value) => {
        try {
            setloading(true);
            const response = await axiosPrivate.post(
                UrlService.getDonationDrugListUrl(),
                { duration: value },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setDrugList(response?.data?.drug_list);
            setloading(false);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
            setloading(false);
        }
    };

    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
            outer_agency_id: data.outer_agency_id.value,
            duration: data.duration.value,
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.saveDonationUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else {
                ToastifyService.saveNotify('Donate Create Successfull');
                setShow(false);
                reset();
                reload();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Body>
                <Form>
                    <Row>
                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>NOTRE/Our Code</Form.Label>
                                <Form.Control
                                    name="our_code"
                                    id="our_code"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register('our_code', {
                                        required: 'Field is required!',
                                    })}
                                    className={errors?.our_code && 'invalidInput'}
                                />

                                {errors.our_code && (
                                    <span className="invalid">{errors.our_code?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Donate To <InstantOuterAgency />
                                </Form.Label>
                                <Controller
                                    name="outer_agency_id"
                                    id="outer_agency_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={
                                                errors?.outer_agency_id && 'invalidInput'
                                            }
                                            options={agencyData}
                                        />
                                    )}
                                    rules={{ required: 'Please select your agency.' }}
                                />

                                {errors.outer_agency_id && (
                                    <span className="invalid">
                                        {errors.outer_agency_id?.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Expire Durations</Form.Label>
                                <Controller
                                    name="duration"
                                    id="duration"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.duration && 'invalidInput'}
                                            options={durationData}
                                            onBlur={() => getDrugList(field?.value?.value)}
                                        />
                                    )}
                                    rules={{ required: 'Please select your duration.' }}
                                />

                                {errors.duration && (
                                    <span className="invalid">{errors.duration?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Button className="btnInfo mt-4" variant="primary">
                                    {loading ? 'Searching...' : 'Search'}
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    {drugList && (
                        <>
                            <Row>
                                <DrugTable drugList={drugList} />
                                {errors.drug_list && (
                                    <span className="invalid mt-2">
                                        {errors.drug_list?.message}
                                    </span>
                                )}
                            </Row>
                            <div className="formFooter">
                                <Button
                                    className="btnCancel me-2"
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    className="btnSuccess mt-1"
                                    onClick={handleSubmit(formSubmitHandler)}
                                    type="button"
                                    variant="success"
                                    disabled={isSubmitted}
                                >
                                    Submit
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
}
