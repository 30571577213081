/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: D1 Form: Medicines Requisition for Facility
 * Description: Master Setup of D1 Form: Medicines Requisition for Facility Form: Create and Update
 * Date: 15/03/2022
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useD2Form } from './ContextAPI';
import RequisitionModalInfo from './RequisitionModalInfo';
import RequisitionTable from './RequisitionTable';
import LoaderSubmit from '../../LoaderSubmit';

export default function FormInfo({ editData = null }) {
    const { addD2Form, editD2Form, getRequisitionData } = useD2Form();

    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        formState: { errors },
        reset,
        handleSubmit,
        control,
        getValues,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                month: editData && new Date(editData.month),
                year: editData && new Date(editData.year),
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            month: editData && new Date(editData.month),
            year: editData && new Date(editData.year),
        });
    }, [editData]);

    // Get Requisition Data
    const monthValue = getValues('month');
    const yearValue = getValues('year');
    const yearHandler = (year) => {
        const dateData = {
            year: year?.getFullYear(),
            month: monthValue?.getMonth(),
        };
        getRequisitionData(dateData);
    };
    const monthHandler = (month) => {
        const dateData = {
            year: yearValue?.getFullYear(),
            month: month?.getMonth(),
        };

        console.log(`dateData`, dateData);
        getRequisitionData(dateData);
    };

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);
        const formData = {
            ...data,
            month: data.month.getMonth() + 1,
            year: data.year.getFullYear(),
        };

        if (editData) {
            editD2Form(formData, editData.id); // Update Data
        } else {
            addD2Form(formData); // Create Data
        }
        reset();
    };

    return (
        <Form>
            <fieldset className="customFieldset2 p-4 pb-2 mb-3">
                <Row>
                    <Col lg={6}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGrnStockID">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Month<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="month"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        selected={field.value}
                                                        placeholderText="Select month"
                                                        dateFormat="MMMM"
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                        onChange={(date) => {
                                                            field.onChange(date);
                                                            monthHandler(date);
                                                        }}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        // minDate={
                                                        //     new Date(
                                                        //         new Date().getFullYear(),
                                                        //         new Date().getMonth() - 1,
                                                        //         new Date().getDate()
                                                        //     )
                                                        // }
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Month is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.month && (
                                                <span className="invalid">
                                                    {errors.month?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGrnStockID">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Year<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="year"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        selected={field.value}
                                                        dateFormat="yyyy"
                                                        showYearPicker
                                                        placeholderText="Select year"
                                                        onChange={(date) => {
                                                            field.onChange(date);
                                                            yearHandler(date);
                                                        }}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        minDate={
                                                            new Date(
                                                                new Date().getFullYear() - 2,
                                                                new Date().getMonth(),
                                                                new Date().getDate()
                                                            )
                                                        }
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Year is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.year && (
                                                <span className="invalid">
                                                    {errors.year?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </fieldset>
            <Row className="requisition_table">
                <Col lg={10}>
                    <fieldset className="customFieldset">
                        <RequisitionTable />
                    </fieldset>
                </Col>
                <Col lg={2} className="ps-0">
                    <fieldset className="customFieldset m-auto h-100 d-flex align-items-center">
                        <Button
                            onClick={handleSubmit(formSubmitHandler)}
                            className="btnSuccess"
                            type="button"
                            variant="success"
                            disabled={isSubmitted}
                        >
                            Generate D2 Form
                        </Button>
                        {isSubmitted && <LoaderSubmit />}
                    </fieldset>
                </Col>
            </Row>

            <RequisitionModalInfo />
        </Form>
    );
}
