/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Consumption Tally
 * Description: Dispensary Consumption Tally Form: Create and Update
 * Date: 26/04/2022
 */

import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useConsumptionReports } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, setData, setShowPdf } = useConsumptionReports();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    // Local State
    const [drugData, setDrugData] = useState([]);
    const [drugCategoryData, setDrugCategoryData] = useState();
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();
    const [drugList, setDrugList] = useState();
    const [partnerList, setPartnerList] = useState();
    const [facilityList, setFacilityList] = useState();
    const [dispensaryList, setDispensaryList] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [err, seterr] = useState('');

    const {
        // register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: auth?.employee_info?.partner_id && {
                    label: auth?.employee_info?.partner_name,
                    value: auth?.employee_info?.partner_id,
                },
                facility_id: auth?.employee_info?.facility_id && {
                    label: auth?.employee_info?.facility_name,
                    value: auth?.employee_info?.facility_id,
                },
                dispensary_id: auth?.employee_info?.dispensary_id && {
                    label: auth?.employee_info?.dispensary_name,
                    value: auth?.employee_info?.dispensary_id,
                },
            }),
            []
        ),
    });

    const {
        drugInfo,
        getDrugInfo,
        drugCategory,
        getDrugCategory,
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();

    const dataList = useCallback(() => {
        getDrugCategory();
        getPartner(); // Partner
    }, []);

    const categoryHandler = (catid) => {
        getDrugInfo(catid);
    };

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        setDrugData(
            drugInfo?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
            }))
        );
        setDrugCategoryData(
            drugCategory?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [drugInfo, drugCategory, partner, facilityByPartner, dispensaryByFacility]);

    // Get Facility by Partner login
    useEffect(() => {
        if (auth?.employee_info?.partner_id) {
            getFacilityByPartner(auth?.employee_info?.partner_id);
        }
    }, [auth?.employee_info?.partner_id]);

    // Get Dispensary by Facility login
    useEffect(() => {
        if (auth?.employee_info?.facility_id) {
            getDispensaryByFacility(auth?.employee_info?.facility_id);
        }
    }, [auth?.employee_info?.facility_id]);

    useEffect(() => {
        if (drugData?.length > 0) {
            setDrugList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...drugData,
            ]);
        }
        if (partnerData?.length > 0) {
            setPartnerList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...partnerData,
            ]);
        }
        if (facilityData?.length > 0) {
            setFacilityList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...facilityData,
            ]);
        }
        if (dispensaryData?.length > 0) {
            setDispensaryList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...dispensaryData,
            ]);
        }
    }, [drugData, partnerData, facilityData, dispensaryData]);

    // const handleClose = () => {
    //     setShow(false);
    //     setData('');
    // };

    // const type = '';

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);
        seterr('');
        const formData = {
            drug_ids: data.drug_id.map((el) => el.value * 1),
            category_id: data.category_id.value,
            start_date: data.date_from.toLocaleDateString('sv-SE'),
            end_date: data.date_to.toLocaleDateString('sv-SE'),
            partner_ids: isArray(data?.partner_id)
                ? data?.partner_id.map((el) => el.value * 1)
                : data?.partner_id?.value
                ? [data.partner_id.value * 1]
                : [],
            facility_ids: isArray(data?.facility_id)
                ? data?.facility_id.map((el) => el.value * 1)
                : data?.facility_id?.value
                ? [data.facility_id.value * 1]
                : [],
            dispensary_ids: isArray(data?.dispensary_id)
                ? data?.dispensary_id.map((el) => el.value * 1)
                : data?.dispensary_id?.value
                ? [data.dispensary_id.value * 1]
                : [],
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.saveConsumptionReportsUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setData(response.data);
            setShowPdf(true);
            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
            setIsSubmitted(false);
        } catch (error) {
            if (!error?.response) {
                console.log('Server Not Responding!');
            } else if (error.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                seterr(error?.response?.data?.msg);
                console.log('Data Insert Failed!');
            }
            setIsSubmitted(false);
        }
    };

    return (
        <Form>
            <Row>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Partner Agency<span className="invalid">*</span>
                        </Form.Label>
                        <Controller
                            name="partner_id"
                            id="partner_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.partner_id && 'invalidInput'}
                                    options={partnerList}
                                    isDisabled={auth?.employee_info?.partner_id}
                                    onBlur={async () => {
                                        await resetField('facility_id');
                                        await resetField('dispensary_id');
                                        await getFacilityByPartner(
                                            field?.value?.length > 0 ? field?.value[0]?.value : 0
                                        );
                                    }}
                                    isMulti
                                />
                            )}
                            rules={{ required: 'Please select your partner.' }}
                        />
                        {errors?.partner_id && (
                            <span className="invalid">{errors?.partner_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Facility Name</Form.Label>
                        <Controller
                            name="facility_id"
                            id="facility_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.facility_id && 'invalidInput'}
                                    options={facilityList}
                                    isDisabled={
                                        auth?.employee_info?.partner_id &&
                                        auth?.employee_info?.facility_id
                                    }
                                    onBlur={async () => {
                                        await resetField('dispensary_id');
                                        await getDispensaryByFacility(
                                            field?.value?.length > 0 ? field?.value[0]?.value : 0
                                        );
                                    }}
                                    isMulti
                                />
                            )}
                            // rules={{ required: 'Please select your facility.' }}
                        />
                        {errors?.facility_id && (
                            <span className="invalid">{errors?.facility_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Department Name</Form.Label>
                        <Controller
                            name="dispensary_id"
                            id="dispensary_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isDisabled={
                                        auth?.employee_info?.partner_id &&
                                        auth?.employee_info?.facility_id &&
                                        auth?.employee_info?.dispensary_id
                                    }
                                    classNamePrefix={errors?.dispensary_id && 'invalidInput'}
                                    options={dispensaryList}
                                    isMulti
                                />
                            )}
                            // rules={{ required: 'Please select your dispensary.' }}
                        />
                        {errors?.dispensary_id && (
                            <span className="invalid">{errors?.dispensary_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Drug Category</Form.Label>
                        <Controller
                            name="category_id"
                            id="category_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.category_id && 'invalidInput'}
                                    options={drugCategoryData}
                                    onBlur={() => categoryHandler(field?.value?.value)}
                                />
                            )}
                            rules={{ required: 'Please select your item.' }}
                        />
                        {errors.category_id && (
                            <span className="invalid">{errors.category_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={8}>
                    <Form.Group className="mb-3">
                        <Form.Label>Drug Name</Form.Label>
                        <Controller
                            name="drug_id"
                            id="drug_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    className="basic-multi-select"
                                    classNamePrefix={errors?.drug_id && 'invalidInput'}
                                    options={drugList}
                                    isMulti
                                />
                            )}
                            rules={{ required: 'Please select your item.' }}
                        />
                        {errors.drug_id && (
                            <span className="invalid">{errors.drug_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Date From</Form.Label>

                        <Controller
                            control={control}
                            name="date_from"
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    closeOnScroll
                                    placeholderText="Select date"
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    className="form-control"
                                    popperPlacement="top-end"
                                    // minDate={new Date()}
                                />
                            )}
                            rules={{
                                required: 'Date is required.',
                                valueAsDate: true,
                            }}
                        />
                        {errors.date_from && (
                            <span className="invalid">{errors.date_from?.message}</span>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Date to</Form.Label>

                        <Controller
                            control={control}
                            name="date_to"
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    closeOnScroll
                                    placeholderText="Select date"
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    className="form-control"
                                    popperPlacement="top-end"
                                    // minDate={new Date()}
                                />
                            )}
                            rules={{
                                required: 'Date is required.',
                                valueAsDate: true,
                            }}
                        />
                        {errors.date_to && (
                            <span className="invalid">{errors.date_to?.message}</span>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Row className="text-end">
                        <Col lg={12}>
                            {/* <Button
                                className="btnCancel me-2"
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button> */}
                            <Button
                                className="btnSuccess"
                                onClick={handleSubmit(formSubmitHandler)}
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                View Report {isSubmitted && '...'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
            <Row className="p-2 text-danger">{err}</Row>
        </Form>
    );
}
