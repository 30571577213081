/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author Enayet Hossain
 * Date: 24/04/2022
 * Time: 02:30 PM
 */
import React, { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';
import { useDispensary } from './ContextAPI';
import FormInfo from './FormInfo';

export default function DataTable() {
    const { data, getAllData } = useDispensary();
    const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.id,
            url: UrlService.deleteDispensaryUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.name,
            sortable: true,
        },

        {
            name: 'Partner',
            selector: (row) => row.partner?.name,
            sortable: true,
            grow: 3,
        },

        {
            name: 'Facility',
            selector: (row) => row.facility?.name,
            sortable: true,
            grow: 2,
        },

        {
            name: 'Status',
            selector: (row) => row?.status,
            cell: (row) =>
                row?.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Active
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Inactive
                    </Badge>
                ),
        },

        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    <FormInfo editData={row} />

                    <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button>
                </>
            ),
        },
    ];
    const search_column = {
        name: 'Name',
        'partner.name': 'Partner',
        'facility.name': 'Facility',
    };
    return useDataTable({ columns, data, search_column });
}
