/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Center Stock In Context
 * Description: Page for Center Stock In Context
 * Date: 15/03/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { reload } from '../../../Utils';

// Initial State
const initialState = {
    centerStockIns: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'CENTER_STOCK_IN_LIST':
            return {
                ...state,
                centerStockIns: action.payload,
            };
        case 'ADD_CENTER_STOCK_IN':
            return {
                ...state,
                centerStockIns: [action.payload, ...state.centerStockIns],
            };
        case 'EDIT_CENTER_STOCK_IN':
            const updateCenterStockIn = action.payload;

            const updateCenterStockIns = state.centerStockIns.map((centerStockIns) => {
                if (centerStockIns.id === updateCenterStockIn.id) {
                    return updateCenterStockIn;
                }
                return centerStockIns;
            });
            return {
                ...state,
                centerStockIns: updateCenterStockIns,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useCenterStockIn() {
    return useContext(ContextAPI);
}

// Provider Component
export function CenterStockInProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [errMsg, setErrMsg] = useState('');
    const [show, setShow] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const [rowData, setRowData] = useState('');
    const [pdfData, setPdfData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        try {
            const response = await axiosPrivate.post(
                id ? UrlService.updateCenterStockInUrl(id) : UrlService.saveCenterStockInUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            // if (id) {
            //     ToastifyService.updateNotify('Data Update Successfull');
            // } else {
            //     ToastifyService.saveNotify('Data Create Successfull');
            // }
            // setShow(false);
            if (response?.data?.status === 'error') {
                setIsSubmitted(false);
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else if (id) {
                ToastifyService.updateNotify('Data Update Successfull');
                setShow(false);
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                setShow(false);
                reload();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else if (id) {
                setErrMsg('Data Update Failed!');
            } else {
                setErrMsg('Data Insert Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllCenterStockInUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: 'CENTER_STOCK_IN_LIST',
                payload: response?.data?.center_stock_in_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };
    // API Function: Fatch All PDF Data
    // const pdfHandler = async (id) => {
    //     try {
    //         const response = await axiosPrivate.get(UrlService.getEditDataCenterStockInUrl(id), {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         const stock_in_info = response?.data?.stock_in_info[0];

    //         setPdfData({ ...stock_in_info });
    //     } catch (err) {
    //         if (!err?.response) {
    //             console.log('Server Not Responding!');
    //         } else if (err.response?.status === 401) {
    //             console.log('Unauthorized!');
    //         } else {
    //             console.log('Data Not Found!');
    //         }
    //     }
    // };

    // Add CenterStockIn
    const addCenterStockIn = (data) => {
        addOrUodateData(data);
        dispatch({
            type: 'ADD_CENTER_STOCK_IN',
            payload: data,
        });
    };

    // Edit CenterStockIn
    const editCenterStockIn = (data, id) => {
        addOrUodateData(data, id);
        dispatch({
            type: 'EDIT_CENTER_STOCK_IN',
            payload: data,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                centerStockIns: state.centerStockIns,
                getAllData,
                addCenterStockIn,
                editCenterStockIn,
                show,
                setShow,
                showPdf,
                setShowPdf,
                pdfData,
                setPdfData,
                rowData,
                setRowData,
                isSubmitted,
                setIsSubmitted,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
