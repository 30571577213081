/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Center Stock In
 * Description: Center Stock In Form Content
 * Date: 31/03/2022
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Countries from '../../../json/Countries.json';
import CommonApiService from '../../../services/CommonApiService';
import GenericForm from '../../masterSetup/generic/FormInfo';
import ManufacturerForm from '../../masterSetup/manufacturer/FormInfo';
import SupplierForm from '../../masterSetup/supplier/FormInfo';
import { useCenterStockIn } from './ContextAPI';
import LoaderSubmit from '../../LoaderSubmit';

export default function FormInfo({ editData = null }) {
    // Transporter Info
    const [transports, setTransports] = useState([]);
    const [transporterShowModal, setTransporterShowModal] = useState(false); // For Edit
    const [transportRowData, setTransportRowData] = useState({}); // For Edit

    // Stock In Details
    const [stockInDetails, setStockInDetails] = useState([]);
    const [stockInDetailsShowModal, setStockInDetailsShowModal] = useState(false); // For Edit
    const [stockInDetailsRowData, setStockInDetailsRowData] = useState({});
    const [poQty, setPoQty] = useState('');

    // Status Check Handling
    let checkePoStatus;
    // eslint-disable-next-line eqeqeq
    if (editData != null && editData.different_then_po == '1') {
        checkePoStatus = 'checked';
    } else if (editData === null) {
        checkePoStatus = 'checked';
    } else {
        checkePoStatus = '';
    }
    const [checked, setChecked] = useState(checkePoStatus);

    const handleDifferentThenPo = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                entry_date: editData ? new Date(editData.entry_date) : new Date(),
                po_date: editData && new Date(editData.po_date),
                invoice_date: editData && new Date(editData.invoice_date),
                supplier_id: editData && {
                    label: editData?.supplier?.name,
                    value: editData?.supplier_id,
                },
                po_no: editData && {
                    label: editData?.po_no,
                    value: editData?.po_no,
                },
                warehouse_id: editData && {
                    label: editData?.warehouse?.name,
                    value: editData?.warehouse_id,
                },
                // transporter: editData && editData?.center_stock_in_transport[0]?.transporter,
                // driver: editData && editData?.center_stock_in_transport[0]?.driver,
                // vehicle_no: editData && editData?.center_stock_in_transport[0]?.vehicle_no,
                // cointainer_no: editData && editData?.center_stock_in_transport[0]?.cointainer_no,
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            entry_date: editData ? new Date(editData.entry_date) : new Date(),
            po_date: editData.po_date ? new Date(editData.po_date) : null,
            invoice_date: editData.invoice_date ? new Date(editData.invoice_date) : null,
            supplier_id: editData && {
                label: editData?.supplier?.name,
                value: editData?.supplier_id,
            },
            po_no: editData && {
                label: editData?.po_no,
                value: editData?.po_no,
            },
            warehouse_id: editData && {
                label: editData?.warehouse?.name,
                value: editData?.warehouse_id,
            },
            // transporter: editData && editData?.center_stock_in_transport[0]?.transporter,
            // driver: editData && editData?.center_stock_in_transport[0]?.driver,
            // vehicle_no: editData && editData?.center_stock_in_transport[0]?.vehicle_no,
            // cointainer_no: editData && editData?.center_stock_in_transport[0]?.cointainer_no,
        });
    }, [editData]);

    // Set default values
    useEffect(() => {
        setValue('transports', transports);
    }, [transports]);

    useEffect(() => {
        setValue('stockInDetails', stockInDetails);
    }, [stockInDetails]);

    // Transports
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });
    // Reset default values of transporter
    useEffect(() => {
        reset2({
            transporter: transportRowData.row && transportRowData?.row?.transporter,
            vehicle_no: transportRowData.row && transportRowData?.row?.vehicle_no,
            driver: transportRowData.row && transportRowData?.row?.driver,
            cointainer_no: transportRowData.row && transportRowData?.row?.cointainer_no,
        });
    }, [transportRowData]);

    // Stock In Details
    const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
        reset: reset3,
        getValues: getValues3,
        setValue: setValue3,
        // setError: setError3,
        clearErrors: clearErrors3,
        control: control3,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });
    // Reset default values of Stockin Details
    useEffect(() => {
        reset3({
            drug_id: stockInDetailsRowData.row && {
                label: stockInDetailsRowData?.row?.drug_info?.code_no
                    ? `${stockInDetailsRowData?.row?.drug_info?.code_no} ${stockInDetailsRowData?.row?.drug_info?.name} ${stockInDetailsRowData?.row?.drug_info.presentation_type?.name}
                ${stockInDetailsRowData?.row?.drug_info?.presentation_strength?.name}`
                    : stockInDetailsRowData?.row?.drug_info?.name,
                value: stockInDetailsRowData?.row?.drug_id,
            },
            po_drug_id: stockInDetailsRowData.row && {
                label: stockInDetailsRowData?.row?.po_generic?.name,
                value: stockInDetailsRowData?.row?.po_drug_id,
            },
            comment: stockInDetailsRowData?.row?.comment,
            po_qty: stockInDetailsRowData?.row?.po_qty,
            batch_no: stockInDetailsRowData?.row?.batch_no,

            mfg_date: stockInDetailsRowData?.row?.mfg_date
                ? new Date(stockInDetailsRowData.row.mfg_date)
                : null,
            expire_date: stockInDetailsRowData?.row?.expire_date
                ? new Date(stockInDetailsRowData.row.expire_date)
                : null,

            manufacturer_id: stockInDetailsRowData.row && {
                label: stockInDetailsRowData?.row?.manufacturer?.name,
                value: stockInDetailsRowData?.row?.manufacturer_id,
            },

            pack_type: stockInDetailsRowData.row && {
                label: stockInDetailsRowData?.row?.pack_type,
                value: stockInDetailsRowData?.row?.pack_type,
            },
            country_id:
                stockInDetailsRowData.row &&
                Countries.filter((el) => el.id === stockInDetailsRowData?.row?.country_id).map(
                    (ele) => ({
                        value: ele.id,
                        label: ele.name,
                    })
                ),
            pack_no: stockInDetailsRowData.row && stockInDetailsRowData?.row?.pack_no,
            pack_size: stockInDetailsRowData.row && stockInDetailsRowData?.row?.pack_size,
            invoice_qty: stockInDetailsRowData.row && stockInDetailsRowData?.row?.invoice_qty,
            receive_qty: stockInDetailsRowData.row && stockInDetailsRowData?.row?.receive_qty,
            rejected_qty: stockInDetailsRowData.row && stockInDetailsRowData?.row?.rejected_qty,
            rejected_reason:
                stockInDetailsRowData.row && stockInDetailsRowData?.row?.rejected_reason,
            currency_type: stockInDetailsRowData.row && {
                label:
                    stockInDetailsRowData?.row?.currency_type === 'bdt'
                        ? 'BDT'
                        : stockInDetailsRowData?.row?.currency_type === 'usd'
                        ? 'USD'
                        : stockInDetailsRowData?.row?.currency_type === 'euro'
                        ? 'EURO'
                        : '',
                value: stockInDetailsRowData?.row?.currency_type,
            },
            currency_amount:
                stockInDetailsRowData.row && stockInDetailsRowData?.row?.currency_amount,
            currency_rate: stockInDetailsRowData.row && stockInDetailsRowData?.row?.currency_rate,
            unit_price: stockInDetailsRowData.row && stockInDetailsRowData?.row?.unit_price,
        });
    }, [stockInDetailsRowData]);

    // Dependancy
    const {
        drugInfo,
        getDrugInfo,
        poDrugInfo,
        getPoDrugInfo,
        suppliers,
        getSuppliers,
        centerWarehouse,
        getCenterWarehouse,
        manufacturers,
        getManufacturers,
        purchaseOrder,
        getPurchaseOrder,
    } = CommonApiService();
    const {
        addCenterStockIn,
        editCenterStockIn,
        setShow,
        setRowData,
        isSubmitted,
        setIsSubmitted,
    } = useCenterStockIn();

    // Local State
    const [suppliersData, setSuppliersData] = useState();
    const [warehouseData, setWarehouseData] = useState();
    const [manufacturersData, setManufacturersData] = useState();
    const [drugAllData, setAllDrugData] = useState();
    const [drugData, setDrugData] = useState();
    const [countryData, setCountryData] = useState();
    const [showTransporterTable, setShowTransporterTable] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [amountInTk, setAmountInTk] = useState(0);
    const [invoiceQty, setInvoiceQty] = useState();
    const [receiveQty, setReceiveQty] = useState();
    const [rejectQty, setRejectQty] = useState();
    const [purchaseOrderData, setPurchaseOrderData] = useState();

    const dataList = useCallback(() => {
        getSuppliers(); // Suppliers
        getCenterWarehouse(); // Warehouse
        getManufacturers(); // Manufacturer
        getPurchaseOrder(); // Purchase Order
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        // Suppliers
        setSuppliersData(
            suppliers?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Warehouse
        setWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Manufacturer
        setManufacturersData(
            manufacturers?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Drug Info
        setDrugData(
            poDrugInfo?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
                purchase_qty: el.purchase_qty,
            }))
        );
        // Drug Info
        setAllDrugData(
            drugInfo?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
                purchase_qty: el.purchase_qty,
            }))
        );
        // Countries
        setCountryData(
            Countries?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // purchaseOrder
        setPurchaseOrderData(
            purchaseOrder?.map((el) => ({
                value: el.purshase_order_no,
                label: el.purshase_order_no,
            }))
        );
    }, [suppliers, centerWarehouse, manufacturers, poDrugInfo, drugInfo, purchaseOrder]);

    // Transporter Info
    const transportAppendHandler = (data) => {
        setShowTransporterTable(true);
        setTransports([...transports, data]);
        reset2({
            transporter: '',
            vehicle_no: '',
            driver: '',
            cointainer_no: '',
        });
    };

    const transportRemoveHandler = (vehicle_no) => {
        const updatedTransport =
            transports && transports.filter((m) => m.vehicle_no !== vehicle_no);
        setTransports(updatedTransport);
    };

    // Transporter Edit: Row Data
    useEffect(() => {
        if (editData) {
            // setTransports(...transports, editData.center_stock_in_transport[0]);
            setTransports(...transports, editData.center_stock_in_transport);
        }
    }, [editData]);

    // Transporter Edit: Model show with edit data
    const transportModalHandler = (row, i) => {
        setTransporterShowModal(true);
        setTransportRowData({ row, i }); // Set Transport Row Data
    };

    // Transporter Edit: Handle Submit
    const transporterEditHandleSubmit = (data) => {
        const list = [...transports];
        list[transportRowData.i] = data;
        setTransports(list);
        setTransporterShowModal(false);
        reset2({
            transporter: '',
            vehicle_no: '',
            driver: '',
            cointainer_no: '',
        });
    };

    const stockInDetailsAppendHandler = (data) => {
        const detailFormData = {
            ...data,
            mfg_date: data?.mfg_date?.toLocaleDateString('sv-SE'),
            expire_date: data?.expire_date?.toLocaleDateString('sv-SE'),
            drug_id: data.drug_id.value,
            generic: { id: data.drug_id.value, name: data.drug_id.label },
            po_drug_id: data?.po_drug_id?.value ? data.po_drug_id.value : '',
            po_generic: data?.po_drug_id?.value
                ? { id: data.po_drug_id.value, name: data.po_drug_id.label }
                : {},
            manufacturer_id: data.manufacturer_id.value,
            manufacturer: { id: data.manufacturer_id.value, name: data.manufacturer_id.label },
            currency_type: data.currency_type.value,
            country_id: data.country_id.value,
            pack_type: data?.pack_type?.value,
            po_qty: poQty,
        };
        // setStockInDetailsData(stockInDetailsData);
        setStockInDetails([...stockInDetails, detailFormData]);
        setShowTable(true);
        reset3({
            drug_id: '',
            po_drug_id: '',
            comment: '',
            po_qty: '',
            batch_no: '',
            mfg_date: '',
            expire_date: '',
            manufacturer_id: '',
            country_id: '',
            pack_type: '',
            pack_no: '',
            pack_size: '',
            invoice_qty: '',
            receive_qty: '',
            rejected_qty: '',
            rejected_reason: '',
            currency_type: '',
            currency_amount: '',
            currency_rate: '',
            unit_price: '',
        });
        setInvoiceQty('');
        setAmountInTk('');
        setPoQty('');
        setChecked('');
    };
    const stockInDetailsRemoveHandler = (genericid) => {
        const updatedStockInDetails =
            stockInDetails && stockInDetails.filter((m) => m.drug_id !== genericid);
        setStockInDetails(updatedStockInDetails);
    };

    // Stock In Details Edit: Row Data
    useEffect(() => {
        if (editData) {
            setStockInDetails(...stockInDetails, editData.stock_in_detail);
        }
    }, [editData]);

    // Stock In Details Edit: Model show with edit data
    const stockInDetailsModalHandler = (row, i) => {
        setStockInDetailsShowModal(true);
        setStockInDetailsRowData({ row, i }); // Set Transport Row Data
    };

    const currencyData = [
        { label: 'BDT', value: 'bdt' },
        { label: 'USD', value: 'usd' },
        { label: 'EURO', value: 'euro' },
    ];

    const unitTypeData = [
        { label: 'Box', value: 'Box' },
        { label: 'Pack', value: 'Pack' },
        { label: 'Bottles', value: 'Bottles' },
        { label: 'Jar/Pot', value: 'Jar/Pot' },
    ];

    const packNoHandler = (packno) => {
        const packSize = getValues3('pack_size');
        const convertedInvoiceQty = packno * packSize;
        setInvoiceQty(convertedInvoiceQty);
        setReceiveQty(convertedInvoiceQty);
        setRejectQty('0');
        setValue3('invoice_qty', convertedInvoiceQty);
        setValue3('receive_qty', convertedInvoiceQty);
        setValue3('reject_qty', '0');
    };

    const packSizeHandler = (packsize) => {
        const packNo = getValues3('pack_no');
        const convertedInvoiceQty = packsize * packNo;
        setInvoiceQty(convertedInvoiceQty);
        setReceiveQty(convertedInvoiceQty);
        setRejectQty('0');
        setValue3('invoice_qty', convertedInvoiceQty);
        setValue3('receive_qty', convertedInvoiceQty);
        setValue3('rejected_qty', '0');
    };

    const invoiceQtyHandler = (value) => {
        setInvoiceQty(value);
        setReceiveQty(value);
        setRejectQty('0');

        setValue3('invoice_qty', value);
        setValue3('receive_qty', value);
        setValue3('rejected_qty', '0');
    };

    // const getReceiveQty = getValues3('receive_qty');

    const receiveQtyHandler = (value) => {
        const invqty = getValues3('invoice_qty');
        // const convertedQty = invqty - value;

        if (value > invqty) {
            // setReceiveQty(getReceiveQty);
            // setValue3('receive_qty', getReceiveQty);
            // setRejectQty('0');
            // setValue3('rejected_qty', '0');
            // setError3('receive_qty', {
            //     type: 'custom',
            //     message: 'Receive qty max from invoice qty',
            // });
        } else {
            setReceiveQty(value);
            setValue3('receive_qty', value);

            // setRejectQty(convertedQty);
            // setValue3('rejected_qty', convertedQty);

            clearErrors3('receive_qty');
        }
    };

    const rejectQtyHandler = (value) => {
        const invqty = getValues3('invoice_qty');
        // const convertedQty = invqty - value;

        if (value > invqty) {
            // setReceiveQty(getReceiveQty);
            // setValue3('receive_qty', getReceiveQty);
            // setRejectQty('0');
            // setValue3('rejected_qty', '0');
            // setError3('rejected_qty', {
            //     type: 'custom',
            //     message: 'Reject qty max from invoice qty',
            // });
        } else {
            // setReceiveQty(convertedQty);
            // setValue3('receive_qty', convertedQty);

            setRejectQty(value);
            setValue3('rejected_qty', value);

            clearErrors3('rejected_qty');
        }
    };

    const currencyAmountHandler = (amount) => {
        const currencyRate = getValues3('currency_rate');
        const convertedAmount = amount * currencyRate;
        setAmountInTk(convertedAmount);
        setValue3('unit_price', convertedAmount);
    };

    const currencyRateHandler = (rate) => {
        const currencyAmount = getValues3('currency_amount');
        const convertedAmount = currencyAmount * rate;
        setAmountInTk(convertedAmount);
        setValue3('unit_price', convertedAmount);
    };

    // Stock In Details Edit: Handle Submit
    const stockInDetailsEditHandleSubmit = async (data) => {
        const rowData = {
            ...data,
            generic: { id: data.drug_id?.value, name: data.drug_id?.label },
            po_generic: { id: data.po_drug_id?.value, name: data.po_drug_id?.label },
            manufacturer: { id: data.manufacturer_id?.value, name: data.manufacturer_id?.label },
            pack_type: data?.pack_type?.value,
        };
        const list = [...stockInDetails];
        list[stockInDetailsRowData.i] = rowData;
        setStockInDetails(list);
        setStockInDetailsShowModal(false);
        reset3({
            drug_id: '',
            po_drug_id: '',
            comment: '',
            po_qty: '',
            batch_no: '',
            mfg_date: '',
            expire_date: '',
            manufacturer_id: '',
            country_id: '',
            pack_type: '',
            pack_no: '',
            pack_size: '',
            invoice_qty: '',
            receive_qty: '',
            rejected_qty: '',
            rejected_reason: '',
            unit_price: '',
            unit: '',
        });
    };

    register('transports', { required: 'Transports is required!' });
    register('stockInDetails', { required: 'Stockin details is required!' });

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            entry_date: data.entry_date && data?.entry_date?.toLocaleDateString('sv-SE'),
            po_date: data.po_date && data?.po_date?.toLocaleDateString('sv-SE'),
            invoice_date: data.invoice_date && data?.invoice_date?.toLocaleDateString('sv-SE'),
            supplier_id: data.supplier_id.value,
            po_no: data.po_no.value,
            warehouse_id: data.warehouse_id.value,
            status: data.status === true ? 1 : 0,
            // transports: [
            //     {
            //         transporter: data.transporter,
            //         vehicle_no: data.vehicle_no,
            //         driver: data.driver,
            //         cointainer_no: data.cointainer_no,
            //     },
            // ],
        };

        if (editData) {
            editCenterStockIn(formData, editData.id); // Update Data
        } else {
            addCenterStockIn(formData); // Create Data
        }
        reset();
    };

    // Form Close
    const handleClose = () => {
        setShow(false);
        setRowData('');
    };
    // Modal Close
    const modalClose = () => {
        setTransporterShowModal(false);
        setStockInDetailsShowModal(false);
    };

    // debounce handler
    // const debounce = (fn) => {
    //     let timer;
    //     // eslint-disable-next-line func-names
    //     return function (...args) {
    //         const context = this;
    //         if (timer) clearTimeout(timer);
    //         timer = setTimeout(() => {
    //             timer = null;
    //             fn.apply(context, args);
    //         }, 800);
    //     };
    // };
    // const handleChange = (value) => {
    //     getPoDrugInfo(value);
    // };
    // const poHandler = useCallback(debounce(handleChange), []);

    const drugItemHandel = (value) => {
        setPoQty(value);
    };

    return (
        <Form key={1}>
            <Row>
                <Col lg={6}>
                    <fieldset className="customFieldset p-4">
                        <Row>
                            {editData && (
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Row className="align-items-center">
                                            <Col lg={4}>
                                                <Form.Label>GRN No</Form.Label>
                                            </Col>
                                            <Col lg={8}>
                                                <Form.Control
                                                    name="grn_no"
                                                    id="grn_no"
                                                    type="text"
                                                    placeholder=""
                                                    disabled
                                                    autoComplete="off"
                                                    {...register('grn_no')}
                                                    className={errors?.grn_no && 'invalidInput'}
                                                />
                                                {errors.grn_no && (
                                                    <span className="invalid">
                                                        {errors.grn_no?.message}
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            )}
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Entry Date<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="entry_date"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        // minDate={new Date()}
                                                        // maxDate={new Date()}
                                                    />
                                                )}
                                                rules={{
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.entry_date && (
                                                <span className="invalid">
                                                    {errors.entry_date?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                PO No<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                name="po_no"
                                                id="po_no"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        classNamePrefix={
                                                            errors?.po_no && 'invalidInput'
                                                        }
                                                        options={purchaseOrderData}
                                                        onBlur={() =>
                                                            getPoDrugInfo(
                                                                field?.value?.value.toString()
                                                            )
                                                        }
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Select PO',
                                                }}
                                            />
                                            {errors.po_no && (
                                                <span className="invalid">
                                                    {errors.po_no?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>PO Date</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="po_date"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field?.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                                rules={{
                                                    // required: 'Date is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.po_date && (
                                                <span className="invalid">
                                                    {errors.po_date?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={2}>
                                            <Form.Label className="float-start">
                                                Supplier<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={10}>
                                            <div className="labelRightButton mb-3">
                                                <SupplierForm instantCreate />
                                            </div>
                                            <Controller
                                                name="supplier_id"
                                                id="supplier_id"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        classNamePrefix={
                                                            errors?.supplier_id && 'invalidInput'
                                                        }
                                                        options={suppliersData}
                                                        onFocus={() => getSuppliers()}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Please select your supplier.',
                                                }}
                                            />
                                            {errors.supplier_id && (
                                                <span className="invalid">
                                                    {errors.supplier_id?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Inv. No<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="invoice_no"
                                                id="invoice_no"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('invoice_no', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors?.invoice_no && 'invalidInput'}
                                            />
                                            {errors.invoice_no && (
                                                <span className="invalid">
                                                    {errors.invoice_no?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>Inv. Date</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="invoice_date"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field?.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                                rules={{
                                                    // required: 'Date is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.invoice_date && (
                                                <span className="invalid">
                                                    {errors.invoice_date?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Location<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                name="warehouse_id"
                                                id="warehouse_id"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        classNamePrefix={
                                                            errors?.warehouse_id && 'invalidInput'
                                                        }
                                                        options={warehouseData}
                                                    />
                                                )}
                                                rules={{ required: 'Please select your location.' }}
                                            />
                                            {errors.warehouse_id && (
                                                <span className="invalid">
                                                    {errors.warehouse_id?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Waybill No<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="waybill_no"
                                                id="waybill_no"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('waybill_no', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors?.waybill_no && 'invalidInput'}
                                            />
                                            {errors.waybill_no && (
                                                <span className="invalid">
                                                    {errors.waybill_no?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
                <Col lg={6}>
                    <fieldset className="customFieldset p-4">
                        <div key={2}>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Transporter<span className="invalid">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                name="transporter"
                                                id="transporter"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register2('transporter', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors2?.transporter && 'invalidInput'}
                                            />
                                            {errors2.transporter && (
                                                <span className="invalid">
                                                    {errors2.transporter?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Label className="pe-5">
                                            Vehicle No<span className="invalid">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            name="vehicle_no"
                                            id="vehicle_no"
                                            type="text"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register2('vehicle_no', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors2?.vehicle_no && 'invalidInput'}
                                        />
                                        {errors2.vehicle_no && (
                                            <span className="invalid">
                                                {errors2.vehicle_no?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="pe-5">Driver</Form.Label>
                                            <Form.Control
                                                name="driver"
                                                id="driver"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register2('driver', {
                                                    // required: 'Field is required!',
                                                })}
                                                className={errors2?.driver && 'invalidInput'}
                                            />
                                            {errors2.driver && (
                                                <span className="invalid">
                                                    {errors2.driver?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="pe-5">
                                                Cointainer/Seal No
                                            </Form.Label>
                                            <Form.Control
                                                name="cointainer_no"
                                                id="cointainer_no"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register2('cointainer_no', {
                                                    // required: 'Field is required!',
                                                })}
                                                className={errors2?.cointainer_no && 'invalidInput'}
                                            />
                                            {errors2.cointainer_no && (
                                                <span className="invalid">
                                                    {errors2.cointainer_no?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} className="borderTop0 text-end mb-3">
                                        <Button
                                            className="btnSteelBlue"
                                            variant="primary"
                                            style={{ minWidth: '100px' }}
                                            onClick={handleSubmit2(transportAppendHandler)}
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Row>
                            <Col lg={12}>
                                <Table className="borderDashed" responsive="sm">
                                    <tbody>
                                        {(editData.center_stock_in_transport ||
                                            showTransporterTable) && (
                                            <tr>
                                                <th>Transporter</th>
                                                <th>Vehicle No</th>
                                                <th>Driver</th>
                                                {/* <th>Cointainer No</th> */}
                                                <th>Action</th>
                                            </tr>
                                        )}
                                        {transports.map((item, i) => (
                                            <tr key={Math.random()}>
                                                <td>{item.transporter}</td>
                                                <td>{item.vehicle_no}</td>
                                                <td>{item.driver}</td>
                                                {/* <td>{item.cointainer_no}</td> */}
                                                <td className="text-end">
                                                    <Button
                                                        className="btn btn-light actionBtn"
                                                        variant="primary"
                                                        onClick={() =>
                                                            transportModalHandler(item, i)
                                                        }
                                                    >
                                                        <i className="fas fa-edit" />
                                                    </Button>
                                                    <Button
                                                        className="btn btn-light actionBtn"
                                                        variant="primary"
                                                        onClick={() =>
                                                            transportRemoveHandler(item.vehicle_no)
                                                        }
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {errors.transports && (
                                    <span className="invalid">{errors.transports?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>

            <fieldset className="customFieldset p-4 mt-3">
                <div key={3}>
                    <Row>
                        <Col lg={11}>
                            <Row className="align-items-center">
                                <Col lg={8}>
                                    <Form.Group className="mb-3">
                                        <div className="labelRightButton">
                                            <Form.Label className="float-start">
                                                Item Name
                                            </Form.Label>
                                        </div>
                                        <Row style={{ marginTop: '-10px' }}>
                                            <Col lg={12}>
                                                <Controller
                                                    name="drug_id"
                                                    id="drug_id"
                                                    control={control3}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            classNamePrefix={
                                                                errors3?.drug_id && 'invalidInput'
                                                            }
                                                            options={drugData}
                                                            onBlur={() =>
                                                                drugItemHandel(
                                                                    field?.value?.purchase_qty
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    rules={{
                                                        required: 'Please select drug item.',
                                                    }}
                                                />
                                                {errors3.drug_id && (
                                                    <span className="invalid">
                                                        {errors3.drug_id?.message}
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>PO Qty</Form.Label>
                                        <Form.Control
                                            name="po_qty"
                                            id="po_qty"
                                            type="text"
                                            disabled
                                            autoComplete="off"
                                            {...register3('po_qty', {
                                                // required: 'Field is required!',
                                            })}
                                            className={errors3?.po_qty && 'invalidInput'}
                                            value={poQty}
                                        />
                                        {errors3.po_qty && (
                                            <span className="invalid">
                                                {errors3.po_qty?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3 d-flex lign-items-center">
                                                <Form.Check
                                                    name="different_then_po"
                                                    id="user-status-switch"
                                                    type="switch"
                                                    checked={checked}
                                                    onClick={() => {
                                                        getDrugInfo();
                                                        handleDifferentThenPo();
                                                    }}
                                                    onChange={() => {
                                                        getDrugInfo();
                                                        handleDifferentThenPo();
                                                    }}
                                                    {...register('different_then_po')}
                                                />
                                                <span className="mt-1">Mismatch Item</span>
                                            </Form.Group>
                                        </Col>
                                        {checked && (
                                            <>
                                                <Col lg={4}>
                                                    <Form.Group className="mb-3">
                                                        <div className="labelRightButton">
                                                            <Form.Label className="float-start">
                                                                Mismatch Item Name
                                                            </Form.Label>
                                                        </div>
                                                        <Row style={{ marginTop: '-10px' }}>
                                                            <Col lg={12}>
                                                                <Controller
                                                                    name="po_drug_id"
                                                                    id="po_drug_id"
                                                                    control={control3}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            classNamePrefix={
                                                                                errors3?.po_drug_id &&
                                                                                'invalidInput'
                                                                            }
                                                                            options={drugAllData}
                                                                            // onBlur={() =>
                                                                            //     drugItemHandel(
                                                                            //         field?.value?.purchase_qty
                                                                            //     )
                                                                            // }
                                                                        />
                                                                    )}
                                                                    rules={{
                                                                        required:
                                                                            'Please select drug item.',
                                                                    }}
                                                                />
                                                                {errors3.po_drug_id && (
                                                                    <span className="invalid">
                                                                        {
                                                                            errors3.po_drug_id
                                                                                ?.message
                                                                        }
                                                                    </span>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Comment</Form.Label>
                                                        <Form.Control
                                                            name="comment"
                                                            id="comment"
                                                            type="text"
                                                            placeholder=""
                                                            autoComplete="off"
                                                            {...register3('comment', {
                                                                // required: 'Field is required!',
                                                            })}
                                                            className={
                                                                errors3?.comment && 'invalidInput'
                                                            }
                                                        />
                                                        {errors3.comment && (
                                                            <span className="invalid">
                                                                {errors3.comment?.message}
                                                            </span>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Col>

                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Batch No.</Form.Label>
                                        <Form.Control
                                            name="batch_no"
                                            id="batch_no"
                                            type="text"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('batch_no', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors3?.batch_no && 'invalidInput'}
                                        />
                                        {errors3.batch_no && (
                                            <span className="invalid">
                                                {errors3.batch_no?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mfg Date</Form.Label>
                                        <Controller
                                            control={control3}
                                            name="mfg_date"
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    closeOnScroll
                                                    placeholderText="Select date"
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field?.value}
                                                    className="form-control"
                                                    popperPlacement="top-end"
                                                    // maxDate={new Date()}
                                                />
                                            )}
                                            rules={{
                                                // required: 'Date is required.',
                                                valueAsDate: true,
                                            }}
                                        />
                                        {errors3.mfg_date && (
                                            <span className="invalid">
                                                {errors3.mfg_date?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Expire Date</Form.Label>
                                        <Controller
                                            control={control3}
                                            name="expire_date"
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    closeOnScroll
                                                    placeholderText="Select date"
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    className="form-control"
                                                    popperPlacement="top-end"
                                                    minDate={new Date()}
                                                />
                                            )}
                                            rules={{
                                                required: 'Date is required.',
                                                valueAsDate: true,
                                            }}
                                        />
                                        {errors3.expire_date && (
                                            <span className="invalid">
                                                {errors3.expire_date?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <div className="labelRightButton">
                                            <Form.Label className="float-start">
                                                Manufaturer
                                            </Form.Label>
                                            <ManufacturerForm instantCreate />
                                        </div>
                                        <Row style={{ marginTop: '-10px' }}>
                                            <Col lg={12}>
                                                <Controller
                                                    name="manufacturer_id"
                                                    id="manufacturer_id"
                                                    control={control3}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            classNamePrefix={
                                                                errors3?.manufacturer_id &&
                                                                'invalidInput'
                                                            }
                                                            options={manufacturersData}
                                                            onFocus={() => getManufacturers()}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: 'Please select Manufacturer.',
                                                    }}
                                                />
                                                {errors3.manufacturer_id && (
                                                    <span className="invalid">
                                                        {errors3.manufacturer_id?.message}
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Country of Manufaturer</Form.Label>
                                        <Controller
                                            name="country_id"
                                            id="country_id"
                                            control={control3}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix={
                                                        errors3?.country_id && 'invalidInput'
                                                    }
                                                    options={countryData}
                                                />
                                            )}
                                            rules={{
                                                required: 'Please select country.',
                                            }}
                                        />
                                        {errors3.country_id && (
                                            <span className="invalid">
                                                {errors3.country_id?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Packing Unit Type <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Controller
                                            name="pack_type"
                                            id="pack_type"
                                            control={control3}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix={
                                                        errors3?.pack_type && 'invalidInput'
                                                    }
                                                    options={unitTypeData}
                                                    // onBlur={() => setZIndexValue3('0')}
                                                    // onFocus={() => setZIndexValue3('10')}
                                                />
                                            )}
                                            rules={{
                                                required: 'Please select your unit type.',
                                            }}
                                        />
                                        {errors3.pack_type && (
                                            <span className="invalid">
                                                {errors3.pack_type?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>No. of Packing Unit</Form.Label>
                                        <Form.Control
                                            name="pack_no"
                                            id="pack_no"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('pack_no')}
                                            className={errors3?.pack_no && 'invalidInput'}
                                            onBlur={(e) => packNoHandler(e.target.value)}
                                        />
                                        {errors3.pack_no && (
                                            <span className="invalid">
                                                {errors3.pack_no?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>No. of Per Packing Unit</Form.Label>
                                        <Form.Control
                                            name="pack_size"
                                            id="pack_size"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('pack_size')}
                                            className={errors3?.pack_size && 'invalidInput'}
                                            onBlur={(e) => packSizeHandler(e.target.value)}
                                        />
                                        {errors3.pack_size && (
                                            <span className="invalid">
                                                {errors3.pack_size?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Invoice Qty</Form.Label>
                                        <Form.Control
                                            name="invoice_qty"
                                            id="invoice_qty"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('invoice_qty', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors3?.invoice_qty && 'invalidInput'}
                                            onBlur={(e) => invoiceQtyHandler(e.target.value)}
                                            defaultValue={invoiceQty}
                                        />
                                        {errors3.invoice_qty && (
                                            <span className="invalid">
                                                {errors3.invoice_qty?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Receive Qty</Form.Label>
                                        <Form.Control
                                            name="receive_qty"
                                            id="receive_qty"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('receive_qty', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors3?.receive_qty && 'invalidInput'}
                                            defaultValue={receiveQty}
                                            onBlur={(e) => receiveQtyHandler(e.target.value)}
                                        />
                                        {errors3.receive_qty && (
                                            <span className="invalid">
                                                {errors3.receive_qty?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Rejected Qty</Form.Label>
                                        <Form.Control
                                            name="rejected_qty"
                                            id="rejected_qty"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('rejected_qty', {
                                                // required: 'Field is required!',
                                            })}
                                            className={errors3?.rejected_qty && 'invalidInput'}
                                            defaultValue={rejectQty}
                                            onBlur={(e) => rejectQtyHandler(e.target.value)}
                                        />
                                        {errors3.rejected_qty && (
                                            <span className="invalid">
                                                {errors3.rejected_qty?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Reason of Reject</Form.Label>
                                        <Form.Control
                                            name="rejected_reason"
                                            id="rejected_reason"
                                            type="text"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('rejected_reason', {
                                                // required: 'Field is required!',
                                            })}
                                            className={errors3?.rejected_reason && 'invalidInput'}
                                        />
                                        {errors3.rejected_reason && (
                                            <span className="invalid">
                                                {errors3.rejected_reason?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group>
                                        <Form.Label>Currency</Form.Label>
                                        <Controller
                                            name="currency_type"
                                            id="currency_type"
                                            control={control3}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix={
                                                        errors3?.currency_type && 'invalidInput'
                                                    }
                                                    options={currencyData}
                                                />
                                            )}
                                            rules={{
                                                required: 'Please select your currency_type.',
                                            }}
                                        />
                                        {errors3.currency_type && (
                                            <span className="invalid">
                                                {errors3.currency_type?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group>
                                        <Form.Label>Item Price (Per Unit)</Form.Label>
                                        <Form.Control
                                            name="currency_amount"
                                            id="currency_amount"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('currency_amount', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors3?.currency_amount && 'invalidInput'}
                                            onBlur={(e) => currencyAmountHandler(e.target.value)}
                                        />
                                        {errors3.currency_amount && (
                                            <span className="invalid">
                                                {errors3.currency_amount?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group>
                                        <Form.Label>Exchange Rate</Form.Label>
                                        <Form.Control
                                            name="currency_rate"
                                            id="currency_rate"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('currency_rate', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors3?.currency_rate && 'invalidInput'}
                                            onBlur={(e) => currencyRateHandler(e.target.value)}
                                        />
                                        {errors3.currency_rate && (
                                            <span className="invalid">
                                                {errors3.currency_rate?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group>
                                        <Form.Label>Amount in BDT (Per Unit)</Form.Label>
                                        <Form.Control
                                            name="unit_price"
                                            id="unit_price"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            disabled
                                            {...register3('unit_price', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors3?.unit_price && 'invalidInput'}
                                            value={amountInTk}
                                        />
                                        {errors3.unit_price && (
                                            <span className="invalid">
                                                {errors3.unit_price?.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                {/* <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Control
                                            name="unit"
                                            id="unit"
                                            type="number"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register3('unit', {
                                                required: 'Field is required!',
                                            })}
                                            className={errors3?.unit && 'invalidInput'}
                                        />
                                        {errors3.unit && (
                                            <span className="invalid">{errors3.unit?.message}</span>
                                        )}
                                    </Form.Group>
                                </Col> */}
                            </Row>
                        </Col>
                        <Col lg={1} className="pt-3">
                            <Button
                                className="btnSteelBlue h-100"
                                variant="primary"
                                // onClick={() => stockInDetailsAppendHandler()}
                                onClick={handleSubmit3(stockInDetailsAppendHandler)}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col>
                        <Table className="borderDashed mt-3" responsive="sm">
                            <tbody>
                                {(editData?.stock_in_detail || showTable) && (
                                    <tr>
                                        <th>Generic Name</th>
                                        <th>Manufacturer</th>
                                        <th>Inv Qty</th>
                                        <th>Rec Qty</th>
                                        <th>Rej Qty</th>
                                        <th>Unit Price</th>
                                        <th>Action</th>
                                    </tr>
                                )}

                                {stockInDetails?.map((el, i) => (
                                    <tr key={Math.random()}>
                                        <td>
                                            {editData
                                                ? `${el.drug_info?.code_no} ${el.drug_info?.name}`
                                                : el.generic?.name}
                                            {el.drug_info?.presentation_type?.name}{' '}
                                            {el.drug_info?.presentation_strength?.name}
                                        </td>
                                        <td>{el?.manufacturer?.name}</td>
                                        <td>{el?.invoice_qty}</td>
                                        <td>{el?.receive_qty}</td>
                                        <td>{el?.rejected_qty}</td>
                                        <td>{el?.unit_price}</td>
                                        <td className="text-end">
                                            <Button
                                                className="btn btn-light actionBtn"
                                                variant="primary"
                                                onClick={() => stockInDetailsModalHandler(el, i)}
                                            >
                                                <i className="fas fa-edit" />
                                            </Button>
                                            <Button
                                                className="btn btn-light actionBtn"
                                                variant="primary"
                                                onClick={() =>
                                                    stockInDetailsRemoveHandler(el.drug_id)
                                                }
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        {errors.stockInDetails && (
                            <span className="invalid">{errors.stockInDetails?.message}</span>
                        )}
                    </Col>
                </Row>
            </fieldset>
            {editData && (
                <>
                    {/* Edit Transporter */}
                    <Modal show={transporterShowModal} className="modalWidth80" onHide={modalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Transporter</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form key={4}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Transporter</Form.Label>
                                            <Form.Control
                                                name="transporter"
                                                id="transporter"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register2('transporter', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors2?.transporter && 'invalidInput'}
                                            />
                                            {errors2.transporter && (
                                                <span className="invalid">
                                                    {errors2.transporter?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="pe-5">Vehicle No</Form.Label>
                                            <Form.Control
                                                name="vehicle_no"
                                                id="vehicle_no"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register2('vehicle_no', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors2?.vehicle_no && 'invalidInput'}
                                            />
                                            {errors2.vehicle_no && (
                                                <span className="invalid">
                                                    {errors2.vehicle_no?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="pe-5">Driver</Form.Label>
                                            <Form.Control
                                                name="driver"
                                                id="driver"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register2('driver', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors2?.driver && 'invalidInput'}
                                            />
                                            {errors2.driver && (
                                                <span className="invalid">
                                                    {errors2.driver?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="pe-5">
                                                Cointainer/Seal No
                                            </Form.Label>
                                            <Form.Control
                                                name="cointainer_no"
                                                id="cointainer_no"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register2('cointainer_no', {
                                                    // required: 'Field is required!',
                                                })}
                                                className={errors2?.cointainer_no && 'invalidInput'}
                                            />
                                            {errors2.cointainer_no && (
                                                <span className="invalid">
                                                    {errors2.cointainer_no?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Modal.Footer className="borderTop0 pe-0">
                                    <Button
                                        className="btnCancel"
                                        variant="secondary"
                                        onClick={modalClose}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        className="btnSuccess"
                                        onClick={handleSubmit2(transporterEditHandleSubmit)}
                                        type="button"
                                        variant="success"
                                    >
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    {/* Edit stock In Details */}
                    <Modal
                        className="modalWidth80"
                        show={stockInDetailsShowModal}
                        onHide={modalClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit stock In Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form key={5}>
                                <Row>
                                    <Col lg={12}>
                                        <Row className="align-items-center">
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <div className="labelRightButton">
                                                        <Form.Label className="float-start">
                                                            Item Name
                                                        </Form.Label>
                                                        <GenericForm instantCreate />
                                                    </div>
                                                    <Row style={{ marginTop: '-10px' }}>
                                                        <Col lg={12}>
                                                            <Controller
                                                                name="drug_id"
                                                                id="drug_id"
                                                                control={control3}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        classNamePrefix={
                                                                            errors3?.drug_id &&
                                                                            'invalidInput'
                                                                        }
                                                                        options={drugData}
                                                                        isDisabled
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        'Please select generic drug.',
                                                                }}
                                                            />
                                                            {errors3.drug_id && (
                                                                <span className="invalid">
                                                                    {errors3.drug_id?.message}
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>PO Qty</Form.Label>
                                                    <Form.Control
                                                        name="po_qty"
                                                        id="po_qty"
                                                        type="text"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('po_qty', {
                                                            // required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.po_qty && 'invalidInput'
                                                        }
                                                    />
                                                    {errors3.po_qty && (
                                                        <span className="invalid">
                                                            {errors3.po_qty?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3 d-flex lign-items-center">
                                                            <Form.Check
                                                                name="different_then_po"
                                                                id="user-status-switch"
                                                                type="switch"
                                                                checked={checked}
                                                                onClick={() => {
                                                                    getDrugInfo();
                                                                    handleDifferentThenPo();
                                                                }}
                                                                onChange={() => {
                                                                    getDrugInfo();
                                                                    handleDifferentThenPo();
                                                                }}
                                                                {...register('different_then_po')}
                                                            />
                                                            <span className="mt-1">
                                                                Mismatch Item
                                                            </span>
                                                        </Form.Group>
                                                    </Col>
                                                    {checked && (
                                                        <>
                                                            <Col lg={4}>
                                                                <Form.Group className="mb-3">
                                                                    <div className="labelRightButton">
                                                                        <Form.Label className="float-start">
                                                                            Mismatch Item Name
                                                                        </Form.Label>
                                                                    </div>
                                                                    <Row
                                                                        style={{
                                                                            marginTop: '-10px',
                                                                        }}
                                                                    >
                                                                        <Col lg={12}>
                                                                            <Controller
                                                                                name="po_drug_id"
                                                                                id="po_drug_id"
                                                                                control={control3}
                                                                                render={({
                                                                                    field,
                                                                                }) => (
                                                                                    <Select
                                                                                        {...field}
                                                                                        classNamePrefix={
                                                                                            errors3?.po_drug_id &&
                                                                                            'invalidInput'
                                                                                        }
                                                                                        options={
                                                                                            drugAllData
                                                                                        }
                                                                                        // onBlur={() =>
                                                                                        //     drugItemHandel(
                                                                                        //         field?.value?.purchase_qty
                                                                                        //     )
                                                                                        // }
                                                                                    />
                                                                                )}
                                                                                rules={{
                                                                                    required:
                                                                                        'Please select drug item.',
                                                                                }}
                                                                            />
                                                                            {errors3.po_drug_id && (
                                                                                <span className="invalid">
                                                                                    {
                                                                                        errors3
                                                                                            .po_drug_id
                                                                                            ?.message
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col lg={4}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Comment</Form.Label>
                                                                    <Form.Control
                                                                        name="comment"
                                                                        id="comment"
                                                                        type="text"
                                                                        placeholder=""
                                                                        autoComplete="off"
                                                                        {...register3('comment', {
                                                                            // required: 'Field is required!',
                                                                        })}
                                                                        className={
                                                                            errors3?.comment &&
                                                                            'invalidInput'
                                                                        }
                                                                    />
                                                                    {errors3.comment && (
                                                                        <span className="invalid">
                                                                            {
                                                                                errors3.comment
                                                                                    ?.message
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Batch No</Form.Label>
                                                    <Form.Control
                                                        name="batch_no"
                                                        id="batch_no"
                                                        type="text"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('batch_no', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.batch_no && 'invalidInput'
                                                        }
                                                    />
                                                    {errors3.batch_no && (
                                                        <span className="invalid">
                                                            {errors3.batch_no?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mfg Date</Form.Label>
                                                    <Controller
                                                        control={control3}
                                                        name="mfg_date"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                closeOnScroll
                                                                placeholderText="Select date"
                                                                dateFormat="dd-MM-yyyy"
                                                                onChange={(date) =>
                                                                    field.onChange(date)
                                                                }
                                                                selected={field?.value}
                                                                className="form-control"
                                                                popperPlacement="top-end"
                                                                // maxDate={new Date()}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: 'Date is required.',
                                                            valueAsDate: true,
                                                        }}
                                                    />
                                                    {errors3.mfg_date && (
                                                        <span className="invalid">
                                                            {errors3.mfg_date?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Expire Date</Form.Label>
                                                    <Controller
                                                        control={control3}
                                                        name="expire_date"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                closeOnScroll
                                                                placeholderText="Select date"
                                                                dateFormat="dd-MM-yyyy"
                                                                onChange={(date) =>
                                                                    field.onChange(date)
                                                                }
                                                                selected={field.value}
                                                                className="form-control"
                                                                popperPlacement="top-end"
                                                                minDate={new Date()}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: 'Date is required.',
                                                            valueAsDate: true,
                                                        }}
                                                    />
                                                    {errors3.expire_date && (
                                                        <span className="invalid">
                                                            {errors3.expire_date?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <div className="labelRightButton">
                                                        <Form.Label className="float-start">
                                                            Manufaturer
                                                        </Form.Label>
                                                        <ManufacturerForm instantCreate />
                                                    </div>
                                                    <Row style={{ marginTop: '-10px' }}>
                                                        <Col lg={12}>
                                                            <Controller
                                                                name="manufacturer_id"
                                                                id="manufacturer_id"
                                                                control={control3}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        classNamePrefix={
                                                                            errors3?.manufacturer_id &&
                                                                            'invalidInput'
                                                                        }
                                                                        options={manufacturersData}
                                                                        onFocus={() =>
                                                                            getManufacturers()
                                                                        }
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        'Please select Manufacturer.',
                                                                }}
                                                            />
                                                            {errors3.manufacturer_id && (
                                                                <span className="invalid">
                                                                    {
                                                                        errors3.manufacturer_id
                                                                            ?.message
                                                                    }
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Country of Manufaturer</Form.Label>
                                                    <Controller
                                                        name="country_id"
                                                        id="country_id"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.country_id &&
                                                                    'invalidInput'
                                                                }
                                                                options={countryData}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: 'Please select country.',
                                                        }}
                                                    />
                                                    {errors3.country_id && (
                                                        <span className="invalid">
                                                            {errors3.country_id?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Packing Unit Type{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Controller
                                                        name="pack_type"
                                                        id="pack_type"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.pack_type &&
                                                                    'invalidInput'
                                                                }
                                                                options={unitTypeData}
                                                                // onBlur={() => setZIndexValue3('0')}
                                                                // onFocus={() => setZIndexValue3('10')}
                                                            />
                                                        )}
                                                        rules={{
                                                            required:
                                                                'Please select your unit type.',
                                                        }}
                                                    />
                                                    {errors3.pack_type && (
                                                        <span className="invalid">
                                                            {errors3.pack_type?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>No. of Packing Unit</Form.Label>
                                                    <Form.Control
                                                        name="pack_no"
                                                        id="pack_no"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('pack_no')}
                                                        className={
                                                            errors3?.pack_no && 'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            packNoHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.pack_no && (
                                                        <span className="invalid">
                                                            {errors3.pack_no?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>No. of Per Packing Unit</Form.Label>
                                                    <Form.Control
                                                        name="pack_size"
                                                        id="pack_size"
                                                        type="text"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('pack_size')}
                                                        className={
                                                            errors3?.pack_size && 'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            packSizeHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.pack_size && (
                                                        <span className="invalid">
                                                            {errors3.pack_size?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Invoice Qty</Form.Label>
                                                    <Form.Control
                                                        name="invoice_qty"
                                                        id="invoice_qty"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('invoice_qty', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.invoice_qty && 'invalidInput'
                                                        }
                                                        defaultValue={invoiceQty}
                                                        onBlur={(e) =>
                                                            invoiceQtyHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.invoice_qty && (
                                                        <span className="invalid">
                                                            {errors3.invoice_qty?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Receive Qty</Form.Label>
                                                    <Form.Control
                                                        name="receive_qty"
                                                        id="receive_qty"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('receive_qty', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.receive_qty && 'invalidInput'
                                                        }
                                                        defaultValue={receiveQty}
                                                        onBlur={(e) =>
                                                            receiveQtyHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.receive_qty && (
                                                        <span className="invalid">
                                                            {errors3.receive_qty?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Rejected Qty</Form.Label>
                                                    <Form.Control
                                                        name="rejected_qty"
                                                        id="rejected_qty"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('rejected_qty', {
                                                            // required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.rejected_qty && 'invalidInput'
                                                        }
                                                        defaultValue={rejectQty}
                                                        onBlur={(e) =>
                                                            rejectQtyHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.rejected_qty && (
                                                        <span className="invalid">
                                                            {errors3.rejected_qty?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Reason of Reject</Form.Label>
                                                    <Form.Control
                                                        name="rejected_reason"
                                                        id="rejected_reason"
                                                        type="text"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('rejected_reason', {
                                                            // required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.rejected_reason &&
                                                            'invalidInput'
                                                        }
                                                    />
                                                    {errors3.rejected_reason && (
                                                        <span className="invalid">
                                                            {errors3.rejected_reason?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Currency</Form.Label>
                                                    <Controller
                                                        name="currency_type"
                                                        id="currency_type"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.currency_type &&
                                                                    'invalidInput'
                                                                }
                                                                options={currencyData}
                                                            />
                                                        )}
                                                        rules={{
                                                            required:
                                                                'Please select your currency_type.',
                                                        }}
                                                    />
                                                    {errors3.currency_type && (
                                                        <span className="invalid">
                                                            {errors3.currency_type?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Item Price (Per Unit)</Form.Label>
                                                    <Form.Control
                                                        name="currency_amount"
                                                        id="currency_amount"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('currency_amount', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.currency_amount &&
                                                            'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            currencyAmountHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.currency_amount && (
                                                        <span className="invalid">
                                                            {errors3.currency_amount?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Exchange Rate</Form.Label>
                                                    <Form.Control
                                                        name="currency_rate"
                                                        id="currency_rate"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('currency_rate', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.currency_rate && 'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            currencyRateHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.currency_rate && (
                                                        <span className="invalid">
                                                            {errors3.currency_rate?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Amount in BDT (Per Unit)
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="unit_price"
                                                        id="unit_price"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        disabled
                                                        {...register3('unit_price', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.unit_price && 'invalidInput'
                                                        }
                                                        value={amountInTk}
                                                    />
                                                    {errors3.unit_price && (
                                                        <span className="invalid">
                                                            {errors3.unit_price?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Modal.Footer className="borderTop0 pe-0">
                                    <Button
                                        className="btnCancel"
                                        variant="secondary"
                                        onClick={modalClose}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        className="btnSuccess"
                                        onClick={handleSubmit3(stockInDetailsEditHandleSubmit)}
                                        type="button"
                                        variant="success"
                                    >
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </>
            )}
            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    onClick={handleSubmit(formSubmitHandler)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                    disabled={isSubmitted}
                >
                    {editData !== '' ? 'Update' : 'Submit'}
                </Button>
                {isSubmitted && <LoaderSubmit />}
            </div>
        </Form>
    );
}
