/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Donation Context Context
 * Description: Donation Context Context
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';
import ToastifyService from '../../../services/ToastifyService';
import { reload } from '../../../Utils';

// Create Context
const ContextAPI = createContext();

export function useDonation() {
    return useContext(ContextAPI);
}

// Provider Component
export function DonationProvider({ children }) {
    // Dependency
    const axiosPrivate = useAxiosPrivate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [donateData, setDonateData] = useState({});
    const [donateList, setDonateList] = useState({});
    const [rowData, setRowData] = useState({});
    const [rowDataList, setRowDataList] = useState({});
    const [showPdf, setShowPdf] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllDonationUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setData(response?.data?.donation_list);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add/Edit Data
    const addOrUodateData = async (data_sunmit, id) => {
        try {
            const response = await axiosPrivate.post(
                id ? UrlService.updateOuterAgencyUrl(id) : UrlService.saveOuterAgencyUrl(),
                JSON.stringify(data_sunmit),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            await getAllData();
            setIsSubmitted(true);

            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else if (id != null) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                reload();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else if (id) {
                setErrMsg('Data Update Failed!');
            } else {
                setErrMsg('Data Insert Failed!');
            }
            ToastifyService.errorNotify(errMsg);
            setIsSubmitted(false);
        }
    };

    // Add OuterAgency
    const addOuterAgency = (value) => {
        addOrUodateData(value);
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                data,
                setData,
                getAllData,
                donateData,
                setDonateData,
                donateList,
                setDonateList,
                isSubmitted,
                setIsSubmitted,
                rowData,
                setRowData,
                rowDataList,
                setRowDataList,
                showPdf,
                setShowPdf,
                addOuterAgency,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
