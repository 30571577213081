/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useD2Approval } from './ContextAPI';
import RequisitionTable from './RequisitionTable';
import LoaderSubmit from '../../LoaderSubmit';
import { reload } from '../../../Utils';
// import { reload } from '../../../Utils';

export default function FormInfo() {
    const {
        setFormShow,
        rowData,
        detailsData,
        setDetailsData,
        receiveData,
        setReceiveData,
        receiveList,
    } = useD2Approval();
    const { centerWarehouse, getCenterWarehouse } = CommonApiService();
    const [warehouseData, setWarehouseData] = useState();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(0);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        detailsData?.records?.map((el) => {
            const itemdata = {};
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.d2_details_id = el.d2_details_id;
            itemdata.central_warehouse_qty = el.central_warehouse_qty;
            itemdata.batch_no = el.batch_no;
            itemdata.category_id = el.category_id;
            itemdata.requested_qty = el.requested_qty;
            itemdata.approved_request_qty = el.approved_request_qty;
            itemdata.approved_qty =
                Number(el.central_warehouse_qty) > Number(el.requested_qty)
                    ? el.approved_request_qty
                    : el.central_warehouse_qty;
            // itemdata.approved_qty = el.approved_qty > 0 ? el.approved_qty : el.approved_request_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            // const arrOfObj = toArray(list);
            // setReceiveList(arrOfObj);
            return receiveData;
        });
    }, [detailsData.records]);

    const {
        register,
        formState: { errors },
        reset,
        // handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList)) {
            setValue('requisition', receiveList);
        }
    }, [receiveList]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
        getCenterWarehouse(); // Center Warehouse
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    useEffect(() => {
        setWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [centerWarehouse]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <RequisitionTable
                    data={detailsData?.records?.filter((el) => el.category_id == item.id)}
                />
            </Tab>
        ));

    // Form Submit Handelar
    const formSubmitHandler = async () => {
        setIsSubmitted(true);

        const formData = {
            d2_master_id: rowData.d2_master_id,
            central_warehouse_id: detailsData.central_warehouse_id,
            requisition: Object.values(receiveData),
        };
        console.log(`data`, formData);
        try {
            await axiosPrivate.post(UrlService.unhcrD2ApprovedUrl(), JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            ToastifyService.updateNotify('D2 Approve Successfull');
            setFormShow(false);
            reset();
            reload();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
        setDetailsData('');
    };

    const approveHandler = async (val) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getD2RecordByWarehouseIdUrl(rowData.d2_master_id, val),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const details_info = {
                d2_master_id: rowData.d2_master_id,
                partner_name: rowData.partner_name,
                month: rowData.month,
                year: rowData.year,
                records: response?.data?.d2_record_list,
                central_warehouse_id: val,
            };
            setDetailsData(details_info);
            setFormShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    register('requisition', { required: 'Requisition quantity is required!' });

    console.log(`receiveData`, receiveData);
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>D2 Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <fieldset className="customFieldset p-4 mb-4">
                    <Form.Group>
                        <Row>
                            <Col lg={2}>
                                <Form.Label>Warehouse</Form.Label>
                            </Col>
                            <Col lg={4}>
                                <Controller
                                    name="central_warehouse_id"
                                    id="central_warehouse_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={
                                                errors?.central_warehouse_id && 'invalidInput'
                                            }
                                            options={warehouseData}
                                            onBlur={() => approveHandler(field?.value?.value)}
                                        />
                                    )}
                                    rules={{ required: 'Please select your warehouse.' }}
                                />
                                {errors.central_warehouse_id && (
                                    <span className="invalid">
                                        {errors.central_warehouse_id?.message}
                                    </span>
                                )}
                            </Col>

                            {!detailsData && (
                                <Col lg={2}>
                                    <Button
                                        type="button"
                                        className="btnSuccess}"
                                        variant="success"
                                        style={{ minWidth: '6rem' }}
                                    >
                                        Next
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form.Group>
                </fieldset>
                {detailsData && (
                    <Form>
                        <Form.Group>
                            <Row>
                                <Tabs
                                    activeKey={page}
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3 ps-3"
                                    // eslint-disable-next-line radix
                                    onSelect={(k) => setPage(parseInt(k))}
                                >
                                    {tableDataDisplay()}
                                </Tabs>
                            </Row>

                            <div className="formFooter">
                                <Button
                                    className="btnCancel"
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                {page !== 0 && (
                                    <Button
                                        className="btnInfo me-2 ms-2"
                                        variant="primary"
                                        style={{ minWidth: '6rem' }}
                                        disabled={page === 0}
                                        onClick={() => {
                                            setPage((currPage) => currPage - 1);
                                        }}
                                    >
                                        Prev
                                    </Button>
                                )}
                                <Button
                                    type="button"
                                    // className={`btnSuccess ${
                                    //     rowData.status === 'd2_prepared' &&
                                    //     page === pageLength - 1 &&
                                    //     'd-none'
                                    // }`}
                                    className={`btnSuccess ${page === pageLength - 1 && 'd-none'}`}
                                    variant="success"
                                    disabled={page === pageLength - 1}
                                    style={{ minWidth: '6rem' }}
                                    onClick={() => setPage((currPage) => currPage + 1)}
                                >
                                    Next
                                </Button>

                                <Button
                                    onClick={formSubmitHandler}
                                    className={`btnSuccess ${page !== pageLength - 1 && 'd-none'}`}
                                    type="button"
                                    variant="success"
                                    style={{ minWidth: '6rem' }}
                                    // disabled={isSubmitted}
                                >
                                    Approve
                                </Button>
                                {isSubmitted && <LoaderSubmit />}
                            </div>
                        </Form.Group>
                    </Form>
                )}
            </Card.Body>
        </Card>
    );
}
