/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: D1 Approval Context
 * Description: D1 Approval Context
 * Date: 20/06/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { reload } from '../../../Utils';

// Initial State
const initialState = {
    d1Approval: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'LIST_DATA':
            return {
                ...state,
                d1Approval: action.payload,
            };
        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useD1Approval() {
    return useContext(ContextAPI);
}

// Provider Component
export function D1ApprovalProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState({});
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { auth } = useAuth();
    const facilityId = auth.employee_info?.facility_id;

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD1FormUrl(facilityId), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: 'LIST_DATA',
                payload: response?.data?.d1_preparation_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add Approval
    const addD1Approval = async (data) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.D1CheckedUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setShow(false);
            getAllData();
            ToastifyService.updateNotify('Approve Successfull');

            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            } else {
                reload();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                d1Approval: state.d1Approval,
                getAllData,
                addD1Approval,
                show,
                setShow,
                rowData,
                setRowData,
                receiveData,
                setReceiveData,
                receiveList,
                setReceiveList,
                isSubmitted,
                setIsSubmitted,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
