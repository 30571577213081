/*
 * Author M. Atoar Rahman
 * Title: App
 * Description: Call AppRoute and ToastContainer
 * Date: 15/03/2022
 */

import AppRoute from '../routes/AppRoute';

export default function App() {
    const numberInputs = document.querySelectorAll('input[type=number]');

    numberInputs.forEach((input) => {
        input.addEventListener('wheel', (event) => {
            event.preventDefault(); // Prevent scrolling
        });
    });

    return <AppRoute />;
}
