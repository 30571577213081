/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty, toArray } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';
import RequisitionTable from './RequisitionTable';
import LoaderSubmit from '../../LoaderSubmit';
import { reload } from '../../../Utils';

export default function FormInfo() {
    const {
        setFormShow,
        rowData,
        setRowData,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
        getPendingData,
        getApprovedData,
    } = useApproval();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.category_id = el.category_id;
            itemdata.central_available_qty = el.central_available_qty;
            itemdata.facility_approved_qty = el.facility_approved_qty;
            itemdata.partner_forward_apv_qty = el.partner_forward_apv_qty;
            itemdata.unhcr_final_approved_qty = el.unhcr_final_approved_qty;
            itemdata.central_distributed_qty = el.central_distributed_qty;
            itemdata.partner_received_qty = el.partner_received_qty;
            itemdata.partner_rejected_qty = el.partner_rejected_qty;
            itemdata.partner_reject_reason = el.partner_reject_reason;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);
            return receiveData;
        });
    }, [rowData.records]);

    const { register, reset, handleSubmit, setValue } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList)) {
            setValue('requisition', receiveList);
        }
    }, [receiveList]);

    const tableDataDisplay = () => <RequisitionTable data={rowData?.records} />;

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: 'partner_receive_apv',
            master_id: rowData.master_id,
            partner_id: rowData.partner_id,
        };
        try {
            await axiosPrivate.post(
                UrlService.internalRequestApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Approve Successfull');
            setFormShow(false);
            reset();
            reload();
            getPendingData();
            getApprovedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
        setRowData('');
    };

    register('requisition', { required: 'Requisition quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Facility Internal Request Receive Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                {tableDataDisplay()}

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className="btnSuccess ms-2"
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Submit
                    </Button>
                    {isSubmitted && <LoaderSubmit />}
                </div>
            </Card.Body>
        </Card>
    );
}
