/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Facility Stock Return
 * Description: Facility Stock Return Form: Create and Update
 * Date: 19/06/2022
 */

import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import UrlService from '../../../services/UrlService';
import { useStockReturn } from './ContextAPI';
import LoaderSubmit from '../../LoaderSubmit';

export default function FormInfo({ editData = null }) {
    const { setShow, addStockReturn, editStockReturn } = useStockReturn();
    const axiosPrivate = useAxiosPrivate();
    // Local State
    const [drugData, setDrugData] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [batchNoData, setBatchNoData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { drugInfo, getDrugInfo } = CommonApiService();

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Item Details
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
        control: control2,
        setValue: setValue2,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        setValue('itemDetails', itemDetails);
    }, [itemDetails]);

    const dataList = useCallback(() => {
        getDrugInfo(); // DrugInfo
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // DrugInfo
        setDrugData(
            drugInfo?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
            }))
        );
    }, [drugInfo]);

    // Item Details: Add
    const itemDetailsAppendHandler = (data) => {
        const detailFormData = {
            ...data,

            drug_id: data.drug_id.value,
            batch_no: data.batch_no.value,
            item: { value: data.drug_id.value, label: data.drug_id.label },
            batchno: { value: data.batch_no.value, label: data.batch_no.label },
        };
        setItemDetails([...itemDetails, detailFormData]);
        reset2({
            drug_id: '',
            batch_no: '',
            available_qty: '',
            return_qty: '',
            return_remark: '',
        });
    };
    // Item Details: Remove
    const itemDetailsRemoveHandler = (drug_id) => {
        const updatedItemDetails = itemDetails && itemDetails.filter((m) => m.drug_id !== drug_id);
        setItemDetails(updatedItemDetails);
    };

    const getRowData = (data) => {
        setValue2('available_qty', data?.available_qty);
        setValue2('facility_stock_in_id', data?.facility_stock_in_id);
    };

    // batchNo: Fatch All Data
    const batchNo = async (id) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.facilityBatchNoListUtilityUrl(),
                { item_id: id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setBatchNoData(
                response.data.batch_no_list.map((el) => ({
                    value: el.batch_no,
                    label: el.batch_no,
                    available_qty: el.available_qty,
                    facility_stock_in_id: el.facility_stock_in_id,
                }))
            );
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    register('itemDetails', { required: 'Item details is required!' });

    // Form Submit Handelar
    const formSubmitHandler = (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
        };
        if (editData) {
            editStockReturn(formData, editData.id); // Update Data
        } else {
            addStockReturn(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    return (
        <Form>
            <Row>
                <Col lg={11}>
                    <Row>
                        <Col lg={4} className="pe-0">
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Item Name<span className="invalid">*</span>
                                </Form.Label>
                                <Controller
                                    name="drug_id"
                                    id="drug_id"
                                    control={control2}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors2?.drug_id && 'invalidInput'}
                                            options={drugData}
                                            onBlur={() => batchNo(field?.value?.value)}
                                        />
                                    )}
                                    rules={{
                                        required: 'Please select drug item.',
                                    }}
                                />
                                {errors2.drug_id && (
                                    <span className="invalid">{errors2.drug_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={2} className="pe-0">
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Batch No <span className="invalid">*</span>
                                </Form.Label>
                                <Controller
                                    name="batch_no"
                                    id="batch_no"
                                    control={control2}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors2?.batch_no && 'invalidInput'}
                                            options={batchNoData}
                                            onBlur={() => getRowData(field.value)}
                                        />
                                    )}
                                    rules={{
                                        required: 'Field is required.',
                                    }}
                                />
                                {errors2.batch_no && (
                                    <span className="invalid">{errors2.batch_no?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={2} className="pe-0">
                            <Form.Group>
                                <Form.Label>Available Qty</Form.Label>
                                <Form.Control
                                    name="available_qty"
                                    id="available_qty"
                                    type="number"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register2('available_qty', {
                                        // required: 'Field is required.',
                                    })}
                                    className={errors2?.available_qty && 'invalidInput'}
                                />
                                {errors2.available_qty && (
                                    <span className="invalid">
                                        {errors2.available_qty?.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={2} className="pe-0">
                            <Form.Group>
                                <Form.Label>
                                    Return Qty <span className="invalid">*</span>
                                </Form.Label>
                                <Form.Control
                                    name="return_qty"
                                    id="return_qty"
                                    type="number"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register2('return_qty', {
                                        required: 'Field is required.',
                                    })}
                                    className={errors2?.return_qty && 'invalidInput'}
                                />
                                {errors2.return_qty && (
                                    <span className="invalid">{errors2.return_qty?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={2} className="pe-0">
                            <Form.Group>
                                <Form.Label>Remark</Form.Label>
                                <Form.Control
                                    name="return_remark"
                                    id="return_remark"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register2('return_remark', {
                                        // required: 'Field is required.',
                                    })}
                                    className={errors2?.return_remark && 'invalidInput'}
                                />
                                {errors2.return_remark && (
                                    <span className="invalid">
                                        {errors2.return_remark?.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1} className="mt-3">
                    <Button
                        className="btnSteelBlue mt-1"
                        variant="primary"
                        onClick={handleSubmit2(itemDetailsAppendHandler)}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <Row>
                {errors.itemDetails && (
                    <span className="invalid">{errors.itemDetails?.message}</span>
                )}
                <Col lg={12}>
                    <Table className="borderDashed" responsive="sm">
                        {itemDetails.length > 0 && (
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Batch No.</th>
                                    <th>Return Qty</th>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {itemDetails.map((item) => (
                                <tr key={Math.random()}>
                                    <td>{item.item?.label}</td>
                                    <td>{item.batchno?.label}</td>
                                    <td>{item.return_qty}</td>
                                    <td>{item.return_remark}</td>
                                    <td className="text-end">
                                        <Button
                                            className="btn btn-light actionBtn"
                                            variant="primary"
                                            onClick={() => itemDetailsRemoveHandler(item.drug_id)}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Row className="text-start">
                        <Col lg={12}>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData ? 'Update' : 'Submit'}
                            </Button>
                            {isSubmitted && <LoaderSubmit />}
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Form>
    );
}
